import styled from '../../utils/styled';

import Input from '../../components/Input';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import Button from '../../components/Button';
import { postAdminLogin } from 'api/admin/login';
import { setCookie } from 'utils/cookie';
import { useNavigate } from 'react-router-dom';

const Container = styled('div', {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const LoginBox = styled('div', {
  minWidth: '300px',
  marginTop: '40px',
});

const Title = styled('h1', {
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '-0.48px',
  margin: 0,
});

export default function Login() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const handleLogin = async () => {
    try {
      await postAdminLogin(username, password)
        .then((result) => {
          setCookie('adminToken', result.token);
          navigate('/admin/resumes', { replace: true });
        })
        .catch((error) => {
          enqueueSnackbar('에러가 발생했습니다. 잠시 후 다시 시도해주세요.', { 
            variant: 'error',
            autoHideDuration: 3000,
            hideIconVariant: true,
          });
        });
    } catch (e: any) {
      if (e.response) {
        enqueueSnackbar(e.response.data?.message, { 
          variant: 'error',
          autoHideDuration: 3000,
          hideIconVariant: true,
        });
      }      
    }
  };

  return (
    <Container>
      <Title>한류 Admin</Title>
      <LoginBox>
        <Input 
          value={username}
          name='username'
          onChange={handleInputChange} 
          onClear={() => setUsername('')}
          placeholder='아이디'
        />
        <Input
          value={password}
          name='password'
          type='password'
          onChange={handleInputChange} 
          onClear={() => setPassword('')}
          className='m-t-20'
          placeholder='비밀번호'
        />
        <Button 
          type='active'
          text='로그인'
          className="w-full m-t-20"
          onClick={handleLogin}
        />
      </LoginBox>
    </Container>
  );
};