import React, { useState, ChangeEvent } from 'react';
import styled from '../../utils/styled';

import Input from '../../components/SmallInput';
import Button from '../../components/Button';
import { CommonFilter, GradeFilter } from 'types/types';

const Container = styled('div', {
  width: '100%',
  padding: '20px',
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  '&.active': {
    backgroundColor: '#000',
    color: '#fff',
  }
});

const InputWrapper = styled('div', {
  width: '600px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const ButtonWrapper = styled('div', {
  width: '600px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginTop: '20px',
});

type Props = {
  q?: string;
  commonFilter?: CommonFilter;
  gradeFilter?: GradeFilter;
  onChangeKeyword: (keyword: string) => void;
  openCommonDialog: () => void;
  openGradeDialog: () => void;
  onSubmit: () => void;
};

export default function ResumeFilter({
  q,
  commonFilter,
  gradeFilter,
  onChangeKeyword,
  openCommonDialog,
  openGradeDialog,
  onSubmit,
}: Props) {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeKeyword(event.target.value);
  };

  const handleClearInput = () => {
    onChangeKeyword('');
  };

  let commonFilterText = '기본사항';

  if (commonFilter) {
    let filterArray = [];
    const { gender, ageGte, ageLte, country } = commonFilter;

    if (gender) {
      filterArray.push(gender === 'male' ? '남성' : gender === 'female' ? '여성' : '무관');
    }
    if (ageGte) {
      filterArray.push(`${ageGte}~${ageLte}대`);
    }
    if (country) {
      filterArray.push(country);
    }
    if (filterArray.length > 0) {
      commonFilterText = filterArray.join(', ');
    }
  }

  let gradeFilterText = '능력 및 학력';
  if (gradeFilter) {
    let filterArray = [];
    const { availableLanguages, TOEICScoreGte, TOEICScoreLte, TOPIKGradeGte, TOPIKGradeLte, HSKGradeGte, HSKGradeLte, degree } = gradeFilter;
    if (degree) {
      filterArray.push(degree === 'university' ? '학사' : degree === 'graduateSchool' ? '석/박사' : '무관');
    }
    if (availableLanguages && availableLanguages.length > 0) {
      filterArray.push(availableLanguages.join(', '));
    }
    let scoreFilter = [];
    if (TOEICScoreGte || TOEICScoreLte) {
      scoreFilter.push('TOEIC');
    }
    if (TOPIKGradeGte || TOPIKGradeLte) {
      scoreFilter.push('TOPIK');
    }
    if (HSKGradeGte || HSKGradeLte) {
      scoreFilter.push('HSK');
    }
    if (filterArray.length > 0) {
      gradeFilterText = filterArray.join(', ');
    }
    if (scoreFilter.length > 0) {
      if (filterArray.length > 0) {
        gradeFilterText += ` (${scoreFilter.join(', ')})`;
      } else {
        gradeFilterText = scoreFilter.join(', ');
      }
    }
  }

  return (
    <Container>
      <InputWrapper>
        <Input 
          value={q}
          onChange={handleInputChange} 
          onClear={handleClearInput}
          placeholder='이름, 이메일, 전화번호, 대학교(원)을 검색해보세요.'
        />
        <Button 
          type='small-dark'
          text='검색'
          onClick={onSubmit}
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button 
          type={commonFilterText !== '기본사항' ? 'small-dark' : 'small'}
          text={commonFilterText}
          onClick={openCommonDialog}
        />
        <Button 
          type={gradeFilterText !== '능력 및 학력' ? 'small-dark' : 'small'}
          text={gradeFilterText}
          onClick={openGradeDialog}
        />
      </ButtonWrapper>
    </Container>
  );
}