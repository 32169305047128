// src/routes.tsx
import React from 'react';
import { RouteObject } from 'react-router-dom';
import Home from './pages/Home/Home';
import Verify from './pages/Verify/Verify';
import Resume from 'pages/Resume/Resume';
import Analysis from 'pages/Analysis/Analysis';
import Confirm from 'pages/Confirm/Confirm';
import Auth from 'pages/Auth/Auth';
import Complete from 'pages/Complete/Complete';
import AdminLogin from 'pages/Admin/AdminLogin';
import AdminResumes from 'pages/Admin/Resumes';

const routes: RouteObject[] = [
  {
    path: '/',
    element: React.createElement(Home),
  },
  {
    path: '/verify',
    element: React.createElement(Verify),
  },
  {
    path: '/resume',
    element: React.createElement(Resume),
  },
  {
    path: '/analysis',
    element: React.createElement(Analysis),
  },
  {
    path: '/confirm',
    element: React.createElement(Confirm),
  },
  {
    path: '/auth',
    element: React.createElement(Auth),
  },
  {
    path: '/complete',
    element: React.createElement(Complete),
  },
  {
    path: '/admin/login',
    element: React.createElement(AdminLogin),
  },
  {
    path: '/admin/resumes',
    element: React.createElement(AdminResumes),
  },
];

export default routes;
