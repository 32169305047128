import React, { useState, ChangeEvent, useEffect } from 'react';
import styled from '../../utils/styled';
import SubmitNav from '../../components/SubmitNav';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { postResume } from 'api/user/resumes';
import { Resume, ResumeForm } from 'types/types';
import { useSnackbar } from 'notistack';
import { getCountries } from 'api/user/countries';

const Container = styled('div', {
  width: '100%',
  paddingTop: '54px',
});

const Contents = styled('div', {
  padding: '4px 20px 32px 20px',
  display: 'flex',
  flexDirection: 'column',
});

const Caution = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(100% - 40px)',
  height: '38px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '-0.14px',
  margin: '0 20px 20px 20px',
  backgroundColor: '#78787A',
  borderRadius: '6px',
  padding: '0 8px',
});

const HalfItemWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  gap: '10px',
});

const placeholders: Record<keyof ResumeForm, string> = {
  name: '이름을 입력하세요',
  age: '나이를 입력하세요',
  gender: '성별을 입력하세요',
  email: '이메일을 입력하세요',
  phoneNumber: '연락처를 입력하세요',
  availableLanguages: '가능한 언어를 입력하세요',
  TOEICScore: '',
  TOPIKGrade: '등급(숫자만)을 입력해주세요.',
  HSKGrade: '등급(숫자만)을 입력해주세요.',
  university: '대학교명을 입력하세요',
  graduateSchool: '대학원명을 입력하세요',
  country: '국적을 입력하세요',
};

const labels: Record<keyof ResumeForm, string> = {
  name: '이름',
  age: '나이',
  gender: '성별',
  email: '이메일',
  phoneNumber: '전화번호',
  availableLanguages: '사용 가능 언어',
  TOEICScore: '',
  TOPIKGrade: '한글 성적 (TOPIK)',
  HSKGrade: '중국어 성적 (HSK)',
  university: '대학',
  graduateSchool: '대학원',
  country: '국적',
};
const captions: Record<keyof ResumeForm, string> = {
  name: '',
  age: '',
  gender: '‘남성’ 또는 ‘여성’을 기입해주세요.',
  email: '이메일 주소(@, com)포함하여 전체 기입해주세요.',
  phoneNumber: '‘-’없이 숫자만 입력해주세요.',
  availableLanguages: '',
  TOEICScore: '',
  TOPIKGrade: '',
  HSKGrade: '',
  university: '',
  graduateSchool: '',
  country: '',
};

const Label = styled('p', {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '150%',
  color: '#17171A',
  marginBottom: '8px',
  marginTop: '0',
});

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState<string[]>([]);
  const [formData, setFormData] = useState<ResumeForm>({
    name: '',
    age: '',
    gender: '',
    email: '',
    phoneNumber: '',
    availableLanguages: [],
    TOEICScore: '',
    TOPIKGrade: '',
    HSKGrade: '',
    university: '',
    graduateSchool: '',
    country: '',
  });

  const [selected, setSelected] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClearInput = (field: string) => {
    setFormData({
      ...formData,
      [field]: '',
    });
  };

  const setSelectedOption = (value: string) => {
    setSelected(value);
  };

  const onSubmit = async () => {
    try {
      const uploadedFileText = localStorage.getItem('uploadedFile');
      let file = null;
      if (uploadedFileText) {
        file = JSON.parse(uploadedFileText);
      }

      if (selected === 'TOEIC') {
        setFormData({
          ...formData,
          TOEICScore: formData.TOEICScore,
          TOPIKGrade: '',
        });
      } else {
        setFormData({
          ...formData,
          TOEICScore: '',
          TOPIKGrade: formData.TOEICScore,
        });
      }
      const response = await postResume(formData, file);
      if (response) {
        localStorage.removeItem('analysisFile');
        localStorage.removeItem('uploadedFile');
        navigate(`/complete?contact=${response.phoneNumber}`, { replace: true });
      }
    } catch (e: any) {
      console.error(e);
      if (e.response) {
        enqueueSnackbar('이름, 성별, 이메일, 사용 가능 언어는 필수 항목입니다.', { 
          variant: 'error',
          autoHideDuration: 3000,
          hideIconVariant: true,
        });
      }
    }
  };

  useEffect(() => {
    const localFileString = localStorage.getItem('analysisFile');
    if (localFileString) {
      const file = JSON.parse(localFileString);
      const rawData = file.rawData;
      setFormData({
        name: rawData.name,
        age: rawData.age,
        gender: rawData.gender,
        email: rawData.email,
        phoneNumber: rawData.phoneNumber,
        availableLanguages: rawData.availableLanguages,
        TOEICScore: rawData.TOEICScore,
        TOPIKGrade: rawData.TOPIKGrade,
        HSKGrade: rawData.HSKGrade,
        university: rawData.university,
        graduateSchool: rawData.graduateSchool,
        country: rawData.country,
      });
    }
    const fetchCountries = async () => {
      const response = await getCountries();
      setCountries(response);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem('analysisFile');
    };
  }, [location]);

  return (
    <Container>
      <SubmitNav text='이력서 검토' onSubmit={onSubmit}/>
      <Caution>이력서를 검토 및 수정하고 [등록] 버튼을 눌러주세요.</Caution>
      <Contents>
        {
          Object.entries(formData).map((data, idx) => (
            idx === 6 
            ? <div className='m-t-20' key={idx}>
              <Label>영어 성적</Label>
              <HalfItemWrapper>
                <Select className='w-half' options={['TOEIC', 'TOPIK']} onSelect={setSelectedOption} selected={selected}/>
                <Input 
                  name='TOEICScore'
                  value={formData.TOEICScore}
                  onChange={handleInputChange}
                  onClear={() => handleClearInput('TOEICScore')}
                  placeholder='점수 (숫자만)'
                  className='w-half'
                />
              </HalfItemWrapper>
            </div>
            : idx === 11
            ? <div className='m-t-20' key={idx}>
              <Label>국적</Label>
              <Select
                isTop={true}
                options={countries}
                onSelect={(value) => handleInputChange({ target: { name: 'country', value } } as ChangeEvent<HTMLInputElement>)}
                selected={formData.country}
              />
            </div>
            : <Input
              key={idx}
              name={data[0]}
              value={data[1]}
              onChange={handleInputChange}
              onClear={() => handleClearInput(data[0])}
              label={labels[data[0] as keyof ResumeForm]}
              placeholder={placeholders[data[0] as keyof ResumeForm]}
              caption={captions[data[0] as keyof ResumeForm]}
              className={idx === 0 ? '' : 'm-t-20'}
            />
          ))
        }
      </Contents>
    </Container>
  );
}
