import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from '../../../utils/styled';

import ToggleButton from '../ToggleButton';
import Button from '../../Button';
import AdminSelect from '../AdminSelect';
import PresetInput from '../PresetInput';
import Checkbox from '../../Checkbox';

import CloseIcon from '../../../assets/icons/close.png';
import { GradeFilter } from 'types/types';

const Header = styled('div', {
  width: '100%',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 10px',
  position: 'relative',
});

const Close = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});

const Title = styled('h2', {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '150%',
  color: '#17171A',
  letterSpacing: '-0.32px', 
  margin: 0,
  whiteSpace: 'pre-wrap',
  '@media screen and (max-width: 330px)': {
    width: '280px',
  },
});

const Save = styled('button', {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '150%',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#007bff',
  letterSpacing: '-0.32px', 
  margin: 0,
  whiteSpace: 'pre-wrap',
  cursor: 'pointer',
});

const CustomUl = styled('ul', {
  padding: '0 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const Label = styled('p', {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '150%',
  color: '#17171A',
  marginBottom: '0',
  marginTop: '0',
});

const Sub = styled('p', {
  width: '100px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '150%',
  color: '#17171A',
  marginBottom: '0',
  marginTop: '0',
});

const NoWrapColumn = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginTop: '8px',
  firstChild: {
    marginTop: '0',
  },
});

const Column = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexWrap: 'wrap',
});

const LabelWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
})

const RightAlign = styled('div', {
  marginLeft: 'auto',
});

type Props = {
  title?: string;
  open: boolean;
  degree?: string;
  availableLanguages?: string[];
  TOEICScoreGte?: number;
  TOEICScoreLte?: number;
  TOPIKGradeGte?: number;
  TOPIKGradeLte?: number;
  HSKGradeGte?: number;
  HSKGradeLte?: number;
  handleClose: () => void;
  onSubmit: (data: GradeFilter) => void;
};

export default function AlertDialog({
  title,
  open,
  degree,
  availableLanguages,
  TOEICScoreGte,
  TOEICScoreLte,
  TOPIKGradeGte,
  TOPIKGradeLte,
  HSKGradeGte,
  HSKGradeLte,
  handleClose,
  onSubmit,
}: Props) {
  const [filterDegree, setFilterDegree] = useState<string>('');
  const [filterLanguages, setFilterLanguages] = useState<string[]>([]);
  const [filterTOEICScoreGte, setFilterTOEICScoreGte] = useState<number|undefined>(undefined);
  const [filterTOEICScoreLte, setFilterTOEICScoreLte] = useState<number|undefined>(undefined);
  const [filterTOPIKScoreGte, setFilterTOPIKScoreGte] = useState<number|undefined>(undefined);
  const [filterTOPIKScoreLte, setFilterTOPIKScoreLte] = useState<number|undefined>(undefined);
  const [filterHSKGradeGte, setFilterHSKGradeGte] = useState<number|undefined>(undefined);
  const [filterHSKGradeLte, setFilterHSKGradeLte] = useState<number|undefined>(undefined);
  const [selected, setSelected] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [topikChecked, setTopikChecked] = useState<boolean>(false);
  const [hskChechked, setHskChecked] = useState<boolean>(false);

  const setSelectedOption = (value: string) => {
    setSelected(value);
  };

  const handleTOEICGte = (e: React.ChangeEvent<HTMLInputElement>) => { setFilterTOEICScoreGte(parseInt(e.target.value)) };
  const handleTOEICLte = (e: React.ChangeEvent<HTMLInputElement>) => { setFilterTOEICScoreLte(parseInt(e.target.value)) };
  const handleTOPIKGte = (e: React.ChangeEvent<HTMLInputElement>) => { setFilterTOPIKScoreGte(parseInt(e.target.value)) };
  const handleTOPIKLte = (e: React.ChangeEvent<HTMLInputElement>) => { setFilterTOPIKScoreLte(parseInt(e.target.value)) };
  const handleHSKGte = (e: React.ChangeEvent<HTMLInputElement>) => { setFilterHSKGradeGte(parseInt(e.target.value)) };
  const handleHSKLte = (e: React.ChangeEvent<HTMLInputElement>) => { setFilterHSKGradeLte(parseInt(e.target.value)) };

  const handleSubmit = () => {
    const filterData: GradeFilter = {
      degree: filterDegree,
      availableLanguages: filterLanguages,
      TOEICScoreGte: filterTOEICScoreGte,
      TOEICScoreLte: filterTOEICScoreLte,
      TOPIKGradeGte: filterTOPIKScoreGte,
      TOPIKGradeLte: filterTOPIKScoreLte,
      HSKGradeGte: filterHSKGradeGte,
      HSKGradeLte: filterHSKGradeLte,
    };
    onSubmit(filterData);
  };

  const selectDegree = (degree: string) => {
    if (filterDegree === degree) {
      setFilterDegree('');
    } else {
      setFilterDegree(degree);
    }
  };

  const selectLanguages = (language: string) => {
    if (filterLanguages.includes(language)) {
      setFilterLanguages(filterLanguages.filter((item) => item !== language));
    } else {
      console.log('2');
      setFilterLanguages([...filterLanguages, language]);
    }
  };

  useEffect(() => {
    if (degree) {
      setFilterDegree(degree);
    }
    if (availableLanguages) {
      setFilterLanguages(availableLanguages);
    }
    if (TOEICScoreGte) {
      setFilterTOEICScoreGte(TOEICScoreGte);
    }
    if (TOEICScoreLte) {
      setFilterTOEICScoreLte(TOEICScoreLte);
    }
    if (TOPIKGradeGte) {
      setFilterTOPIKScoreGte(TOPIKGradeGte);
    }
    if (TOPIKGradeLte) {
      setFilterTOPIKScoreLte(TOPIKGradeLte);
    }
    if (HSKGradeGte) {
      setFilterHSKGradeGte(HSKGradeGte);
    }
    if (HSKGradeLte) {
      setFilterHSKGradeLte(HSKGradeLte);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Header>
        <Close src={CloseIcon} onClick={handleClose} />
        <Title id="alert-dialog-title">{title}</Title>
        <Save onClick={handleSubmit}>저장</Save>
      </Header>
      <CustomUl>
        <div>
          <LabelWrapper>
            <Label>학위</Label>
          </LabelWrapper>
          <Column>
            <ToggleButton 
              type={filterDegree === 'university' ? 'active' : 'default'}
              text='대학교 졸업'
              onClick={() => selectDegree('university')}
            />
            {/* <ToggleButton 
              type='default'
              text='대학원 졸업'
              onClick={() => selectDegree('master')}
            /> */}
            <ToggleButton 
              type={filterDegree === 'graduateSchool' ? 'active' : 'default'}
              text='대학원 졸업'
              onClick={() => selectDegree('graduateSchool')}
            />
            <ToggleButton 
              type={filterDegree === 'free' ? 'active' : 'default'}
              text='무관'
              onClick={() => selectDegree('free')}
            />
          </Column>
        </div>
        <div>
          <LabelWrapper>
            <Label>사용 가능 언어</Label>
          </LabelWrapper>
          <Column>
            <ToggleButton 
              type={filterLanguages.includes('중국어') ? 'active' : 'default'}
              text='중국어'
              onClick={() => selectLanguages('중국어')}
            />
            <ToggleButton 
              type={filterLanguages.includes('한국어') ? 'active' : 'default'}
              text='한국어'
              onClick={() => selectLanguages('한국어')}
            />
            <ToggleButton 
              type={filterLanguages.includes('일본어') ? 'active' : 'default'}
              text='일본어'
              onClick={() => selectLanguages('일본어')}
            />
            <ToggleButton 
              type={filterLanguages.includes('영어') ? 'active' : 'default'}
              text='영어'
              onClick={() => selectLanguages('영어')}
            />
            <ToggleButton 
              type={filterLanguages.includes('불어') ? 'active' : 'default'}
              text='불어'
              onClick={() => selectLanguages('불어')}
            />
            <ToggleButton 
              type={filterLanguages.includes('무관') ? 'active' : 'default'}
              text='무관'
              onClick={() => selectLanguages('무관')}
            />
          </Column>
        </div>
        <div>
          <LabelWrapper>
            <Label>영어 성적</Label>
            {/* <Button 
              type='small'
              text='추가'
            /> */}
          </LabelWrapper>
          <NoWrapColumn>
            <AdminSelect 
              className='w-100'
              placeholder='유형'
              options={['TOEIC']}
              onSelect={setSelectedOption}
              selected={selected}
            />
            <PresetInput 
              value={filterTOEICScoreGte?.toString() || ''}
              onChange={handleTOEICGte}
              placeholder='0'
              preset='점 이상'
            />
            <PresetInput 
              value={filterTOEICScoreLte?.toString() || ''}
              onChange={handleTOEICLte}
              placeholder='0'
              preset='점 이하'
            />
            {/* <Checkbox checked={checked} onClick={() => setChecked(!checked)} label='무관' /> */}
            {/* <RightAlign>
              <Close src={CloseIcon} />
            </RightAlign> */}
          </NoWrapColumn>
        </div>
        <div>
          <LabelWrapper>
            <Label>이 외 언어 성적</Label>
          </LabelWrapper>
          <NoWrapColumn>
            <Sub>TOPIK</Sub>
            <PresetInput
              value={filterTOPIKScoreGte?.toString() || ''}
              onChange={handleTOPIKGte}
              placeholder='0'
              preset='점 이상'
            />
            <PresetInput 
              value={filterTOPIKScoreLte?.toString() || ''}
              onChange={handleTOPIKLte}
              placeholder='0'
              preset='점 이하'
            />
            {/* <Checkbox checked={topikChecked} onClick={() => setTopikChecked(!topikChecked)} label='무관' /> */}
          </NoWrapColumn>
          <NoWrapColumn>
            <Sub>HSK</Sub>
            <PresetInput 
              value={filterHSKGradeGte?.toString() || ''}
              onChange={handleHSKGte}
              placeholder='0'
              preset='점 이상'
            />
            <PresetInput 
              value={filterHSKGradeLte?.toString() || ''}
              onChange={handleHSKLte}
              placeholder='0'
              preset='점 이하'
            />
            {/* <Checkbox checked={hskChechked} onClick={() => setHskChecked(!hskChechked)} label='무관' /> */}
          </NoWrapColumn>
        </div>
      </CustomUl>
    </Dialog>
  );
}
