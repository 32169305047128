import React, { useState, useRef } from 'react';
import styled from '../../utils/styled';

import ArrowDown from '../../assets/icons/click-arrow-down.png';

const SelectWrapper = styled('div', {
  position: 'relative',
  display: 'inline-block',
  width: '250px',
});

const Selected = styled('div', {
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  cursor: 'pointer',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#929293',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '150%',
  padding: '8px 12px',
  '&.active': {
    color: '#17171A',
  },
});

const ArrowIcon = styled('img', {
  width: '24px',
  height: '24px',
  marginLeft: '10px',
  '&.open': {
    transform: 'rotate(180deg)',
  },
});

const Dropdown = styled('div', {
  position: 'absolute',
  top: 'calc(100% + 4px)',
  left: 0,
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: 'none',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
});

const SelectOption = styled('div', {
  padding: '12px 16px',
  cursor: 'pointer',
  borderRadius: '8px',
  '&:hover': {
    background: '#f0f0f0',
  },
});

type Props = {
  options: string[];
  placeholder?: string;
  selected: string;
  onSelect: (value: string) => void;
  className?: string;
}

export default function CustomSelect({
  options,
  placeholder,
  selected,
  onSelect,
  className
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value: string) => {
    onSelect(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SelectWrapper className={className} ref={selectRef}>
      <Selected className={selected ? 'active' : ''} onClick={toggleDropdown}>
        {selected || placeholder}
        <ArrowIcon className={isOpen ? 'open' : ''} src={ArrowDown} />
      </Selected>
      {isOpen && (
        <Dropdown>
          {options.map((option) => (
            <SelectOption
              key={option}
              onClick={() => handleSelect(option)}
            >
              {option}
            </SelectOption>
          ))}
        </Dropdown>
      )}
    </SelectWrapper>
  );
};
