import React, { type HTMLAttributes } from 'react';
import styled from '../utils/styled';

const Button = styled('button', {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '7px',
  backgroundColor: '#E2F2E9',
  padding: '7px 14px',
  color: '#04411E',
  border: 'none',
  fontSize: '14px',
  fontWeight: '600',
  cursor: 'pointer',
});

type Props = {
  text?: string;
} & HTMLAttributes<HTMLButtonElement>;

export default function PrimaryButton({
  text,
  ...props
}: Props) {
  return (
    <Button {...props}>
      <span>
        {text}
      </span>
    </Button>
  );
}
