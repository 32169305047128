import React, { ChangeEvent } from 'react';
import styled from '../utils/styled';

import ClearIcon from '../assets/icons/clear.png';

const Container = styled('div', {
  width: '500px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '6px 12px',
  border: '1px solid #E5E5E5',
  backgroundColor: '#fff',
  flexShrink: 0,
});

const Label = styled('p', {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '150%',
  color: '#17171A',
  marginBottom: '8px',
  marginTop: '0',
});

const InputField = styled('input', {
  width: '100%',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '150%',
  color: '#17171A',
  border: 'none',
  backgroundColor: 'transparent',
  '&::placeholder': {
    color: '#929293',
  },
  '&:focus': {
    outline: 'none',
  },
});

const SmallImage = styled('img', {
  width: '24px',
  height: '24px',
  flexShrink: 0,
});

const Caption = styled('p', {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '150%',
  color: '#929293',
  marginBottom: '0',
  marginTop: '8px',
});

type Props = {
  name?: string;
  value?: string;
  maxLength?: number;
  placeholder?: string;
  type?: string;
  className?: string;
  label?: string;
  caption?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
};

export default function PrimaryButton({
  name,
  value, 
  maxLength,
  onChange, 
  onClear,
  placeholder = '', 
  type = 'text', 
  className = '',
  label = '',
  caption = '',
}: Props) {
  return (
    <div className={className}>
      {
        label &&
        <Label>{label}</Label>
      }
      <Container>
        <InputField 
          name={name}
          type={type}
          value={value} 
          maxLength={maxLength}
          onChange={onChange} 
          placeholder={placeholder} 
        />
        {
          value &&
          <SmallImage src={ClearIcon} onClick={onClear} />
        }
      </Container>
      {
        caption &&
        <Caption>{caption}</Caption>
      }
    </div>
  );
}
