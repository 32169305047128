import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from '../../../utils/styled';
import { CommonFilter } from 'types/types';

import ToggleButton from '../ToggleButton';
import Slider from '../Slider';

import CloseIcon from '../../../assets/icons/close.png';

const Header = styled('div', {
  width: '100%',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 10px',
  position: 'relative',
});

const Close = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});

const Title = styled('h2', {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '150%',
  color: '#17171A',
  letterSpacing: '-0.32px', 
  margin: 0,
  whiteSpace: 'pre-wrap',
  '@media screen and (max-width: 330px)': {
    width: '280px',
  },
});

const Save = styled('button', {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '150%',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#007bff',
  letterSpacing: '-0.32px', 
  margin: 0,
  whiteSpace: 'pre-wrap',
  cursor: 'pointer',
});

const CustomUl = styled('ul', {
  width: '343px',
  padding: '0 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const Label = styled('p', {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '150%',
  color: '#17171A',
  marginBottom: '8px',
  marginTop: '0',
});

const Column = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

type Props = {
  title?: string;
  open: boolean;
  ageGte?: number;
  ageLte?: number;
  gender?: string;
  countries?: string[];
  handleClose: () => void;
  onSubmit: (data: CommonFilter) => void;
};

export default function AlertDialog({
  title,
  open,
  ageGte,
  ageLte,
  gender,
  countries,
  handleClose,
  onSubmit,
}: Props) {
  const [filterGender, setFilterGender] = useState<string>('')
  const [filterAgeGte, setFilterAgeGte] = useState<number>(30);
  const [filterAgeLte, setFilterAgeLte] = useState<number>(40);
  const [filterCountry, setFilterCountry] = useState<string>('');

  const handleSubmit = () => {
    const filterData: CommonFilter = {
      gender: filterGender,
      ageGte: filterAgeGte,
      ageLte: filterAgeLte,
      country: filterCountry,
    };
    onSubmit(filterData);
  };

  const handleChangeAge = (newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setFilterAgeGte(newValue);
      setFilterAgeLte(newValue + 10);
    } else {
      setFilterAgeGte(newValue[0]);
      setFilterAgeLte(newValue[1]);
    }
  };

  const selectGender = (gender: string) => {
    if (filterGender === gender) {
      setFilterGender('');
    } else {
      setFilterGender(gender);
    }
  };

  const selectCountry = (country: string) => {
    if (filterCountry === country) {
      setFilterCountry('');
    } else {
      setFilterCountry(country);
    }
  };

  useEffect(() => {
    if (gender) {
      setFilterGender(gender);
    }
    if (ageGte) {
      setFilterAgeGte(ageGte);
    }
    if (ageLte) {
      setFilterAgeLte(ageLte);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Header>
        <Close src={CloseIcon} onClick={handleClose} />
        <Title id="alert-dialog-title">{title}</Title>
        <Save onClick={handleSubmit}>저장</Save>
      </Header>
      <CustomUl>
        <>
          <Label>성별</Label>
          <Column>
            <ToggleButton 
              type={filterGender === 'male' ? 'active' : 'default'}
              text='남성'
              onClick={() => selectGender('male')}
            />
            <ToggleButton 
              type={filterGender === 'female' ? 'active' : 'default'}
              text='여성'
              onClick={() => selectGender('female')}
            />
            <ToggleButton 
              type={filterGender === 'free' ? 'active' : 'default'}
              text='무관'
              onClick={() => selectGender('free')}
            />
          </Column>
        </>
        <>
          <Label>연령대</Label>
          <Slider 
            ageGte={filterAgeGte}
            ageLte={filterAgeLte}
            onFilterChange={handleChangeAge}
          />
        </>
        <>
          <Label>국적</Label>
          <Column>
            {countries?.map((country, index) => (
              <ToggleButton 
                key={index}
                type={filterCountry === country ? 'active' : 'default'}
                text={country}
                onClick={() => selectCountry(country)}
              />
            ))}
          </Column>
        </>
      </CustomUl>
    </Dialog>
  );
}
