import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 20,
    label: '20대',
  },
  {
    value: 30,
    label: '30대',
  },
  {
    value: 40,
    label: '40대',
  },
  {
    value: 50,
    label: '50대',
  },
];

function valuetext(value: number) {
  return `${value}대`;
}

type Props = {
  ageGte?: number;
  ageLte?: number;
  onFilterChange: (data: number|number[]) => void;
};

export default function RangeSlider({
  ageGte,
  ageLte,
  onFilterChange,
}: Props) {
  const [value, setValue] = React.useState<number[]>([30, 40]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    onFilterChange(newValue);
  };

  return (
    <Box sx={{ width: 300, padding: '0 20px' }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={20}
        max={50}
        step={10}
        marks={marks}
      />
    </Box>
  );
}
