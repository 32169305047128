import React from 'react';
import styled from '../utils/styled';

import { getCookie } from '../utils/cookie';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from './PrimaryButton';
import LogoImage from '../assets/icons/logo.png';

const NavContainer = styled('div', {
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '9px 20px',
  boxSizing: 'border-box',
  backgroundColor: '#FFFFFF',
});

const Logo = styled('img', {
  width: '32px',
  height: '28px',
});

export default function HomeNav() {
  const navigate = useNavigate();
  const isLogin = getCookie('token') ? true : false;
  
  const goToMyResume = () => {
    if (!isLogin) {
      goToLogin();
      return;
    }
    navigate('/resume');
  };
  
  const goToLogin = () => {
    navigate('/verify');
  };

  return (
    <NavContainer>
      <Logo src={LogoImage}/>
      <PrimaryButton text="내 이력서" onClick={goToMyResume} />
    </NavContainer>
  );
}
