import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from '../utils/styled';

const Title = styled('h2', {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '150%',
  color: '#17171A',
  letterSpacing: '-0.32px', 
  width: '279px',
  padding: '20px 20px 16px 20px',
  margin: 0,
  whiteSpace: 'pre-wrap',
  '@media screen and (max-width: 330px)': {
    width: '280px',
  },
});

const Description = styled('p', {
  width: '279px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '160%',
  color: '#5F5F60',
  letterSpacing: '-0.14px',
  margin: 0,
  padding: '0 20px',
  whiteSpace: 'pre-wrap',
  '@media screen and (max-width: 330px)': {
    width: '240px',
  },
});

const CustomUl = styled('ul', {
  padding: '0 20px',
});

const ButtonWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '0 16px 16px 16px',
});

const Button = styled('button', {
  width: '50%',
  minHeight: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  padding: '8px 14px',
  border: 'none',
  fontSize: '14px',
  fontWeight: '500',
  cursor: 'pointer',
  variants: {
    type: {
      positive: {
        backgroundColor: '#17171a',
        color: '#ffff',
      },
      negative: {
        backgroundColor: '#F6F6F6',
        color: '#53585F',
      }
    },
  },
});

type Props = {
  title?: string;
  description?: string[];
  negativeButton?: string;
  positiveButton?: string;
  open: boolean;
  handleClose: () => void;
};

export default function AlertDialog({
  title,
  description,
  negativeButton = '취소',
  positiveButton = '확인',
  open,
  handleClose,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Title id="alert-dialog-title">{title}</Title>
      <CustomUl>
        <Description id="alert-dialog-description">
          {
            description?.map((text, index) => (
              <li key={index}>{text}</li>
            ))
          }
        </Description>
      </CustomUl>
      <ButtonWrapper>
        <Button type={'positive'} onClick={handleClose} autoFocus>{positiveButton}</Button>
        <Button type={'negative'} onClick={handleClose}>{negativeButton}</Button>
      </ButtonWrapper>
    </Dialog>
  );
}
