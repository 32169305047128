import React from 'react';
import styled from '../../utils/styled';

import Button from '../../components/Button';
import { removeCookie, setCookie } from 'utils/cookie';
import { useNavigate } from 'react-router-dom';

const NavContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  padding: '16px 20px',
  boxSizing: 'border-box',
  backgroundColor: '#f9f9f9',
  zIndex: 100,
});

const Title = styled('p', {
  fontSize: '20',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '-0.48px',
  margin: 0,
});

export default function HomeNav() {
  const navigate = useNavigate();
  const handleLogout = () => {
    removeCookie('adminToken');
    navigate('/admin/login', { replace: true });
  };

  return (
    <NavContainer>
      <Title>한류 Admin</Title>
      <Button text="로그아웃" type='small-dark' onClick={handleLogout}/>
    </NavContainer>
  );
}
