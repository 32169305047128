import React from 'react';
import styled from '../utils/styled';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '../assets/icons/close.png';

const NavContainer = styled('div', {
  width: '100%',
  height: '54px',
  maxWidth: '500px',
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '0 20px',
  backgroundColor: '#FFFFFF',
});

const Close = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  marginLeft: 'auto',
});

export default function HomeNav() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <NavContainer>
      <Close src={CloseIcon} onClick={goBack}/>
    </NavContainer>
  );
}
