import React from 'react';
import styled from '../utils/styled';

import ActiveImage from '../assets/icons/checkbox-active.png';
import InactiveImage from '../assets/icons/checkbox-inactive.png';

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '8px',
});

const CheckboxImg = styled('img', {
  width: '24px',
  height: '24px',
});

const Label = styled('p', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '-0.14px',
  color: '#17171A',
  margin: 0,
});

type Props = {
  checked?: boolean;
  label?: string;
  onClick?: () => void;
};

export default function Checkbox({
  checked = false,
  label,
  onClick,
}: Props) {
  return (
    <Container onClick={onClick}>
      <CheckboxImg src={checked ? ActiveImage : InactiveImage} />
      {
        label && <Label>{label}</Label>
      }
    </Container>
  );
}