import React from 'react';
import styled from '../utils/styled';
import { keyframes } from '@stitches/react';

import AiFolder from '../assets/illust/ai_folder.png';
import AiFiles from '../assets/illust/ai_files.png';
import AiFirstPerson from '../assets/illust/ai_person1.png';
import AiSafe from '../assets/illust/ai_safe.png';
import AiSecondPerson from '../assets/illust/ai_person2.png';
import AiNew from '../assets/illust/ai_new.png';

const ToLeftSlide = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(-548px)' },
});

const ToRightSlide = keyframes({
  '0%': { transform: 'translateX(-548px)' },
  '100%': { transform: 'translateX(0)' },
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '1080px',
  gap: '20px',
});

const TopLayout = styled('div', {
  width: '100%',
  animation: `${ToLeftSlide} 10s linear infinite`,
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const BottomLayout = styled('div', {
  width: '100%',
  animation: `${ToRightSlide} 10s linear infinite`,
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const SquareContent = styled('img', {
  width: '100px',
  height: '100px',
  flexShrink: 0,
});

const RectangleContent = styled('img', {
  width: '300px',
  height: '100px',
  flexShrink: 0,
});

const BannerWrapper = styled('div', {
  display: 'flex',
  gap: '16px',
});

export default function RollingBanner() {
  const countArray = [1, 2];
  return (
    <Container>
      <TopLayout>
        {countArray.map((count, index) => (
          <BannerWrapper key={index}>
            <SquareContent src={AiFolder} />
            <SquareContent src={AiFiles} />
            <RectangleContent src={AiFirstPerson} />
          </BannerWrapper>
        ))}
      </TopLayout>
      <BottomLayout>
        {countArray.map((count, index) => (
          <BannerWrapper key={index}>
            <SquareContent src={AiSafe} />
            <RectangleContent src={AiSecondPerson} />
            <SquareContent src={AiNew} />
          </BannerWrapper>
        ))}
      </BottomLayout>
    </Container>
  );
}