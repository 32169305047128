import React from 'react';
import styled from '../utils/styled';

import DraftImg from '../assets/icons/draft-small.png';
import ClearImg from '../assets/icons/clear.png';

const Container = styled('div', {
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '0 8px',
  border: 'none',
  margin: '0 auto',
  marginBottom: '24px',
  backgroundColor: '#F6F6F6',
});

const FileName = styled('span', {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '-0.14px',
  color: '#17171A',
  margin: '0 8px 0 4px',
});

const SmallImage = styled('img', {
  width: '20px',
  height: '20px',
  flexShrink: 0,
  cursor: 'pointer',
});

type Props = {
  fileName?: string;
  onRemove?: () => void;
};

export default function PrimaryButton({
  fileName,
  onRemove,
}: Props) {
  return (
    <Container>
      <SmallImage src={DraftImg} />
      <FileName>{fileName}</FileName>
      <SmallImage src={ClearImg} onClick={onRemove} />
    </Container>
  );
}
