import React, { useState, ChangeEvent } from 'react';
import styled from '../../utils/styled';
import TitleNav from '../../components/TitleNav';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

const Container = styled('div', {
  width: '100%',
});

const Contents = styled('div', {
  height: '100vh',
  padding: '12px 20px',
  paddingTop: '54px',
});

const Title = styled('h2', {
  color: '#17171A',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '-0.48px',
  margin: 0,
  marginBottom: '28px',
});

const ButtonWrapper = styled('div', {
  margin: '12px 0 20px 0',
});

export default function Home() {
  const [inputValue, setInputValue] = useState<string>('');
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [messageSended, setMessageSended] = useState<boolean>(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (inputValue.length >= 5 && isChecked) {
      setIsActiveButton(true);
    } else {
      setIsActiveButton(false);
    }
  };

  const handleClearInput = () => {
    setInputValue('');
    setIsActiveButton(false);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setIsActiveButton(false);
    } else {
      if (inputValue.length >= 5) {
        setIsActiveButton(true);
      } else {
        setIsActiveButton(false);
      }
    }
  };

  const checkStep = () => {
    if (!isActiveButton) {
      return;
    }
    if (!messageSended) {
      setInputValue('');
      setMessageSended(true);
      setIsActiveButton(false);
      return;
    }
  };

  return (
    <Container>
      <TitleNav text={'본인인증'} />
      <Contents>
        <Title>채용 제안받을<br></br>핸드폰 번호를 입력해주세요</Title>
        <Input 
          value={inputValue}
          maxLength={messageSended ? 6 : 11}
          onChange={handleInputChange} 
          onClear={handleClearInput}
          placeholder={messageSended ? '6자리 인증번호' : '휴대전화번호 입력'}
        />
        <ButtonWrapper>
          {
            messageSended
            ? <Button type={'small'} text="인증번호 재전송" />
            : <Checkbox checked={isChecked} onClick={handleChecked} label='개인정보 처리 방침 동의' />
          }
        </ButtonWrapper>
        <Button 
          type={isActiveButton ? 'active': 'default'}
          text={messageSended ? '인증하고 이력서 등록하기' : '인증번호 전송'}
          className="w-full"
          onClick={checkStep}
        />
      </Contents>
    </Container>
  );
}
