import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '../../utils/styled';
import TitleNav from '../../components/TitleNav';
import FileUploadButton from '../../components/FileUploadButton';
import UploadFile from '../../components/UploadFile';
import Button from '../../components/Button';

import DraftImg from '../../assets/icons/draft.png';
import { getCookie } from 'utils/cookie';
import { File } from 'types/types';

const Container = styled('div', {
  width: '100%',
});

const Contents = styled('div', {
  height: '100dvh',
  padding: '0 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ContentBox = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const LargeImage = styled('img', {
  width: '100px',
  height: '100px',
  flexShrink: 0,
});

const Description = styled('p', {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '-0.32px',
  color: '#484849',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
});

const ButtonWrapper = styled('div', {
  width: '100%',
  paddingTop: '40px',
});

interface UploadedFile {
  name: string;
  location: string;
}

export default function Resume() {
  const navigate = useNavigate();

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const onChangeFile = async (file: File) => {
    setUploadedFile(file);
  };

  const goToAnalysis = () => {
    if (uploadedFile) {
      localStorage.setItem('uploadedFile', JSON.stringify(uploadedFile));
      navigate('/analysis');
    }
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
  };

  useEffect(() => {
    if (!getCookie('token')) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <Container>
      <TitleNav text={'내 이력서 관리'} />
      <Contents>
        <ContentBox>
          <LargeImage src={DraftImg} />
          <Description>{'등록하실 이력서를\n.DOC  .JPG  .PDF 형태로 업로드 해주세요.'}</Description>
          {
            uploadedFile && (
              <UploadFile
                fileName={uploadedFile.name}
                onRemove={removeUploadedFile}
              />
            )
          }
          <FileUploadButton 
            type={uploadedFile ? 'uploaded' : 'default'}
            onUploadFile={onChangeFile}
          />
          {
            uploadedFile && (
              <ButtonWrapper>
                <Button className='w-full' type='active' text={'이력서 분석하기'} onClick={goToAnalysis} />
              </ButtonWrapper>
            )
          }
        </ContentBox>
      </Contents>
    </Container>
  );
}
