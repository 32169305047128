import React, { useState, ChangeEvent } from 'react';
import styled from '../../utils/styled';
import TitleNav from '../../components/TitleNav';
import Input from '../../components/Input';
import Button from '../../components/Button';
import AlertDialog from '../../components/AlertDialog';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { PinCodeResult } from '../../types/types';
import { postPinCodes } from 'api/pinCodes';
import { postLogin } from 'api/login';
import { setCookie } from 'utils/cookie';

const Container = styled('div', {
  width: '100%',
});

const Contents = styled('div', {
  height: '100vh',
  padding: '12px 20px',
  paddingTop: '54px',
});

const Title = styled('h2', {
  color: '#17171A',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '-0.48px',
  margin: 0,
  marginBottom: '28px',
});

const ButtonWrapper = styled('div', {
  margin: '12px 0 0 0',
});

export default function Home() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>('');
  const [inputPhoneNumber, setInputPhoneNumber] = useState<string>('');
  const [pinCodeToken, setPinCodeToken] = useState<string>('');
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [sended, setSended] = useState<boolean>(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (inputValue.length >= 5) {
      setIsActiveButton(true);
    } else {
      setIsActiveButton(false);
    }
  };

  const handleClearInput = () => {
    setInputValue('');
    setIsActiveButton(false);
  };

  const handleSendPinCodes = async () => {
    if (isActiveButton || sended) {
      await postPinCodes(inputValue || inputPhoneNumber)
        .then((result: PinCodeResult) => {
          setSended(true);
          setInputPhoneNumber(inputValue);
          setInputValue('');
          setIsActiveButton(false);
          setPinCodeToken(result.token);
        })
        .catch((error) => {
          setOpen(true);
        });
    }
  };

  const handleAuth = async () => {
    if (isActiveButton) {
      await postLogin(pinCodeToken, inputValue)
        .then((result) => {
          setCookie('token', result.token);
          navigate('/resume', { replace: true });
        })
        .catch((error) => {
          setOpen(true);
        });
    }
  };

  return (
    <Container>
      <TitleNav text={'본인인증'} />
      <Contents>
        {
          sended 
          ? <Title>문자로 전송된<br />인증번호를 입력해주세요</Title>
          : <Title>본인 확인을 위해<br />핸드폰 번호를 입력해주세요</Title>
        }
        <Input 
          value={inputValue}
          maxLength={sended ? 6 : 11}
          onChange={handleInputChange} 
          onClear={handleClearInput}
          placeholder={sended ? '6자리 인증번호' : '휴대전화번호 입력'}
        />
        {
          sended && (
            <ButtonWrapper>
              <Button type={'small'} text="인증번호 재전송" onClick={handleSendPinCodes} />
            </ButtonWrapper>
          )
        }
        <Button 
          type={isActiveButton ? 'active': 'default'}
          text={sended ? '인증하기' : '인증번호 발송'}
          className="w-full m-t-20"
          onClick={sended ? handleAuth : handleSendPinCodes}
        />
      </Contents>
      <AlertDialog 
        title={'인증 정보가 올바르지 않거나\n등록된 이력서가 없습니다.'}
        description={['입력한 정보가 정확한지 확인해주세요.', '이력서가 등록되지 않은 경우, 이력서를 등록 후 이용해주세요.']}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Container>
  );
}
