import apiClient from '../axiosInstance';

import { ResumeRequest } from 'types/types';

export const getResumes = async (query?: ResumeRequest) => {
  const response = await apiClient.get('/admin/resumes', {
    params: {
      ...query,
    },
  });
  return response.data;
};

export const getResumesCount = async (query?: ResumeRequest) => {
  const response = await apiClient.get('/admin/resumes/count', {
    params: {
      ...query,
    },
  });
  return response.data;
};