import React, { ChangeEvent, useEffect, useState } from 'react';

import styled from '../../utils/styled';
import { CommonFilter, GradeFilter } from 'types/types';

import AdminNav from '../../components/admin/AdminNav';
import BasicTable from '../../components/admin/BasicTable';
import Pagination from '@mui/material/Pagination';
import ResumeFilter from '../../components/admin/ResumeFilter';
import Select from '../../components/admin/AdminSelect';
import CommonDialog from '../../components/admin/dialog/CommonFilter';
import GradeDialog from '../../components/admin/dialog/GradesFilter';
import { Resume, ResumeQuery } from 'types/types';
import { getResumes, getResumesCount } from 'api/admin/resumes';
import { getCountries } from 'api/admin/countries';

const Container = styled('div', {
  width: '100%',
  minHeight: '100vh',
  paddingTop: '62px',
});

const Content = styled('div', {
  padding: '20px',
});

const Title = styled('h2', {
  color: '#17171A',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '-0.48px',
  margin: 0,
  marginBottom: '28px',
});

const TableWrapper = styled('div', {
  padding: '20px 0'
});

const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const SelectWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '20px',
});

export default function Login() {
  const [selected, setSelected] = useState<string>('최근 제출 순');
  const [search, setSearch] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [openGrade, setOpenGrade] = useState<boolean>(false);
  const [resumes, setResumes] = useState<Resume[]>([]);
  const [resumesCount, setResumesCount] = useState<number>(0);
  const [countries, setCountries] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [commonFilter, setCommonFilter] = useState<CommonFilter>({} as CommonFilter);
  const [gradeFilter, setGradeFilter] = useState<GradeFilter>({} as GradeFilter);
  const [queryData, setQueryData] = useState<ResumeQuery>({
    q: '',
    gender: '',
    ageGte: undefined,
    ageLte: undefined,
    country: '',
    degree: '',
    availableLanguages: [],
    TOEICScoreGte: undefined,
    TOEICScoreLte: undefined,
    TOPIKGradeGte: undefined,
    TOPIKGradeLte: undefined,
    HSKGradeGte: undefined,
    HSKGradeLte: undefined,
    sort: '-createdAt',
  });

  const setSelectedOption = (value: string) => {
    setSelected(value);
  };

  const openCommonDialog = () => {
    setOpen(true);
  };

  const openGradeDialog = () => {
    setOpenGrade(true);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeKeyword = (keyword: string) => {
    setQueryData({
      ...queryData,
      q: keyword,
    });
  };

  const handleSubmit = () => {
    setSearch(true);
    setPage(1);
  };

  const handleCommonFilterSubmit = (data: CommonFilter) => {
    setOpen(false);
    setCommonFilter(data);
    queryData.gender = data.gender === 'male' ? '남성' : data.gender === 'female' ? '여성' : '';
    queryData.ageGte = data.ageGte;
    queryData.ageLte = data.ageLte;
    queryData.country = data.country;
    handleSubmit();
  };

  const handleGradeFilterSubmit = (data: GradeFilter) => {
    setOpenGrade(false);
    setGradeFilter(data);
    queryData.degree = data.degree;
    queryData.availableLanguages = data.availableLanguages;
    queryData.TOEICScoreGte = data.TOEICScoreGte;
    queryData.TOEICScoreLte = data.TOEICScoreLte;
    queryData.TOPIKGradeGte = data.TOPIKGradeGte;
    queryData.TOPIKGradeLte = data.TOPIKGradeLte;
    queryData.HSKGradeGte = data.HSKGradeGte;
    queryData.HSKGradeLte = data.HSKGradeLte;
    handleSubmit();
  };

  useEffect(() => {
    if (search) {
      const fetchResumes = async () => {
        const response = await getResumes({
          ...queryData,
          availableLanguages: queryData.availableLanguages?.join(','),
          offset: (page - 1) * 20,
          limit: 20
        });
        setResumes(response);
      };
  
      const fetchResumesCount = async () => {
        const response = await getResumesCount({
          ...queryData,
          availableLanguages: queryData.availableLanguages?.join(','),
        });
        setResumesCount(response?.count);
      };

      const fetchCountries = async () => {
        const response = await getCountries();
        setCountries(response);
      };
  
      fetchResumes();
      fetchResumesCount();
      fetchCountries();
    }
    return () => {
      setSearch(false);
    }
  }, [page, search]);

  return (
    <Container>
      <AdminNav />
      <Content>
        <Title>이력서 관리</Title>
        <ResumeFilter 
          q={queryData.q}
          commonFilter={commonFilter}
          gradeFilter={gradeFilter}
          onChangeKeyword={handleChangeKeyword}
          openCommonDialog={openCommonDialog}
          openGradeDialog={openGradeDialog}
          onSubmit={handleSubmit}
        />
        <TableWrapper>
          {/* <SelectWrapper>
            <Select options={['최근 제출 순', '제출 늦은 순']} onSelect={setSelectedOption} selected={selected} placeholder='정렬 조건' />
          </SelectWrapper> */}
          <BasicTable data={resumes}/>
        </TableWrapper>
        <PaginationWrapper>
          <Pagination count={Math.ceil(resumesCount / 20)} shape="rounded" onChange={handlePageChange} />
        </PaginationWrapper>
      </Content>
      <CommonDialog 
        title={'기본 사항 필터'}
        open={open}
        ageGte={queryData.ageGte}
        ageLte={queryData.ageLte}
        gender={queryData.gender}
        countries={countries}
        handleClose={() => setOpen(false)}
        onSubmit={handleCommonFilterSubmit}
      />
      <GradeDialog 
        title={'학력 및 능력 필터'}
        open={openGrade}
        handleClose={() => setOpenGrade(false)}
        onSubmit={handleGradeFilterSubmit}
      />
    </Container>
  );
};