import { postUpload } from 'api/upload';
import React, { type ButtonHTMLAttributes, useState, useRef } from 'react';
import styled from '../utils/styled';

import { File } from 'types/types';

const Button = styled('button', {
  minHeight: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  border: 'none',
  fontWeight: '500',
  cursor: 'pointer',
  fontSize: '14px',
  padding: '5px 12px',
  margin: '0',
  variants: {
    type: {
      default: {
        backgroundColor: '#E2F2E9',
        color: '#04411E',
      },
      uploaded: {
        backgroundColor: '#F6F6F6',
        color: '#ABABAC',
      },
    },
  },
  '&:hover': {
    opacity: 0.9,
  },
});

type Props = {
  type: 'default' | 'uploaded';
  className?: string;
  onUploadFile: (file: File) => void;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

export default function FileUploadButton({
  type = 'default',
  className,
  onUploadFile,
  ...props
}: Props) {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = async (event: any) => {
    event.preventDefault();
    const { files } = event.target;
    const [file] = files;
    const formData = new FormData();
    formData.append('file', file);
    const response = await postUpload(formData);
    onUploadFile(response);
  };
  return (
    <>
      <Button
        className={className}
        type={type}
        {...props}
        onClick={handleButtonClick}
      >
        파일 선택
      </Button>
      <input 
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  );
}
