import React from 'react';
import styled from '../../utils/styled';

import { getCookie } from '../../utils/cookie';
import { useNavigate } from 'react-router-dom';

import HomeNav from '../../components/HomeNav';
import Button from '../../components/Button';
import RollingBanner from '../../components/RollingBanner';

const Container = styled('div', {
  width: '100%',
  position: 'relative',
});

const SubTitle = styled('h2', {
  color: '#929293',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.32px',
  margin: 0,
  textAlign: 'center',
});

const Content = styled('div', {
  paddingTop: '132px',
  paddingBottom: '60px',
});

const Title = styled('h1', {
  color: '#17171A',
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '-0.56px',
  margin: 0,
  marginTop: '8px',
  marginBottom: '32px',
  textAlign: 'center',
});

const SubWrapper = styled('div', {
  paddingTop: '128px',
});

export default function Home() {
  const navigate = useNavigate();
  const isLogin = getCookie('token') ? true : false;
  
  const goToMyResume = () => {
    if (!isLogin) {
      goToLogin();
      return;
    }
    navigate('/resume');
  };
  
  const goToLogin = () => {
    navigate('/verify');
  };

  return (
    <Container>
      <HomeNav />
      <Content>
        <SubTitle>
          간편한 AI 채용 서비스
        </SubTitle>
        <Title>
        이력서 등록 한 번으로<br />채용 제안까지
        </Title>
        <Button type={'dark'} text="이력서 등록하기" onClick={goToMyResume} />
        <SubWrapper>
          <RollingBanner />
        </SubWrapper>
      </Content>
    </Container>
  );
}
