import React, { useEffect } from 'react';
import styled from './utils/styled';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './routes';
import './index.css';
import { CookiesProvider, useCookies } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import NotFound from 'components/NotFound';
import RouteGuard from './RouteGuard';
import AdminLogin from 'pages/Admin/AdminLogin';
import AdminResumes from 'pages/Admin/Resumes';

const Root = styled('div', {
  width: '100%',
  maxWidth: '500px',
  minHeight: '100vh',
  margin: '0 auto',
  overflow: 'hidden',
  '&.admin': {
    maxWidth: '100%',
    overflow: 'unset',
  },
});

export default function App() {
  const hasCookie = useCookies(['token']);
  const hasAdminCookie = useCookies(['adminToken']);
  const isLogin = hasCookie[0].token ? true : false;
  const isAdminLogin = hasAdminCookie[0].adminToken ? true : false;
  
  return (
    <SnackbarProvider>
      <CookiesProvider>
        <Router>
          <Routes>
            <Route
              path="/admin"
              element={isAdminLogin ? <AdminResumes /> : <AdminLogin />} 
            />
            {
              routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.path?.startsWith('/admin') 
                    ? 
                    // route.element
                    <RouteGuard isLogin={isAdminLogin} path={route.path}>
                      {route.element}
                    </RouteGuard>
                    : <Root>{route.element}</Root>
                  } 
                />
              ))
            }
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </CookiesProvider>
    </SnackbarProvider>
  );
}
