import apiClient from './axiosInstance';
import { File } from 'types/types';

export const postUpload = async (formData: any): Promise<File> => {
  const response = await apiClient.post('/upload', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};