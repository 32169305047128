import styled from '../utils/styled';

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '60px',
});

const NotFound = () => {
  return (
    <Container>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for doesn't exist.</p>
    </Container>
  );
};

export default NotFound;
