import React, { useEffect, useState } from 'react';
import styled from '../../utils/styled';
import CloseNav from '../../components/CloseNav';
import { getCookie } from 'utils/cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { File } from 'types/types';
import { postFile } from 'api/analysisFiles';

const Container = styled('div', {
  width: '100%',
  position: 'relative',
});

const Content = styled('div', {
  height: '100dvh',
  padding: '0 20px',
  paddingTop: '134px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const Title = styled('h3', {
  color: '#17171A',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '150%',
  letterSpacing: '-0.4px',
  margin: 0,
  textAlign: 'center',
});

const Sub = styled('p', {
  color: '#5F5F60',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '-0.14px',
  margin: 0,
  marginTop: '4px',
  textAlign: 'center',
});

const LoadingVideo = styled('video', {
  width: '300px',
  height: '300px',
});

export default function Home() {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (!getCookie('token')) {
      navigate('/', { replace: true });
      setIsMounted(false);
      return () => {};
    }
    setIsMounted(true);
    const localFile = localStorage.getItem('uploadedFile');
    if (localFile) {
      const file = JSON.parse(localStorage.getItem('uploadedFile') || '');

      const analysisFile = async () => {
        if (file) {
          const response = await postFile(file);
          if (response && isMounted) {
            localStorage.setItem('analysisFile', JSON.stringify(response));
            navigate('/confirm', { replace: true });
          }
        }
      };
      analysisFile();
    }
    return () => {
      setIsMounted(false);
    }
  }, []);

  return (
    <Container>
      <CloseNav />
      <Content>
        <LoadingVideo autoPlay loop>
          <source src={`${process.env.PUBLIC_URL}/loading.mp4`} type="video/mp4" />
        </LoadingVideo>
        <Title>이력서 분석중...</Title>
        <Sub>조금만 기다려주세요.</Sub>
      </Content>
    </Container>
  );
}
