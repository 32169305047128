import styled from '../../utils/styled';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Resume } from 'types/types';

type Props = {
  data: Resume[];
};

const LinkText = styled('p', {
  color: '#007bff',
  cursor: 'pointer',
  margin: 0,
});

export default function BasicTable({
  data,
}: Props) {
  const download = (data: Resume) => {
    if (data?.file?.location) {
      const a = document.createElement("a");
      a.href = data?.file?.location;
      a.target = "_blank";
      a.download = `${data.name}(${data.age}세 ${data.gender}).pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">이름</TableCell>
            <TableCell align="left">나이</TableCell>
            <TableCell align="left">성별</TableCell>
            <TableCell align="left">이메일</TableCell>
            <TableCell align="left">전화번호</TableCell>
            <TableCell align="left">사용 가능 언어</TableCell>
            <TableCell align="left">대학</TableCell>
            <TableCell align="left">대학원</TableCell>
            <TableCell align="left">TOEIC</TableCell>
            <TableCell align="left">TOPIK</TableCell>
            <TableCell align="left">HSK</TableCell>
            <TableCell align="left">국적</TableCell>
            <TableCell align="left">파일</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <TableCell component="th" scope="row">
                {row.name}
              </TableCell> */}
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.age}</TableCell>
              <TableCell align="left">{row.gender}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.phoneNumber}</TableCell>
              <TableCell align="left">{row.availableLanguages?.join(', ') || '-'}</TableCell>
              <TableCell align="left">{row.university || '-'}</TableCell>
              <TableCell align="left">{row.graduateSchool || '-' }</TableCell>
              <TableCell align="left">{row.TOEICScore || '-'}</TableCell>
              <TableCell align="left">{row.TOPIKGrade || '-'}</TableCell>
              <TableCell align="left">{row.HSKGrade || '-'}</TableCell>
              <TableCell align="left">{row.country || '-'}</TableCell>
              <TableCell align="left" onClick={() => download(row)}><LinkText>다운로드</LinkText></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
