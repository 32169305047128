// PrivateRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
  isLogin: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, isLogin, path }) => {
  if (!isLogin && path !== '/admin/login') {
    return <Navigate to="/admin/login" replace />;
  }
  return <>{children}</>;
};

export default PrivateRoute;
