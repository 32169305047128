import React from 'react';
import styled from '../utils/styled';

import { useNavigate } from 'react-router-dom';

import ArrowLeft from '../assets/icons/arrow-left-black.png';

const NavContainer = styled('div', {
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '15px 20px',
  backgroundColor: '#FFFFFF',
});

const BackIcon = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});

const Title = styled('p', {
  color: '#17171A',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '150%',
  letterSpacing: '-0.32px',
  position: 'absolute',
  left: '50%',
  margin: 0,
  marginTop: '-4px',
  transform: 'translateX(-50%)',
});

type Props = {
  text?: string;
};

export default function HomeNav({
  text,
}: Props) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <NavContainer>
      <BackIcon src={ArrowLeft} onClick={goBack}/>
      <Title>
        {text}
      </Title>
    </NavContainer>
  );
}
