import React, { type ButtonHTMLAttributes } from 'react';
import styled from '../utils/styled';

const Button = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  padding: '13px 24px 15px 24px',
  border: 'none',
  fontSize: '16px',
  fontWeight: '500',
  cursor: 'pointer',
  margin: '0 auto',
  variants: {
    type: {
      default: {
        backgroundColor: '#F6F6F6',
        color: '#ABABAC',
      },
      active: {
        backgroundColor: '#72BF9C',
        color: '#FFFFFF',
      },
      dark: {
        backgroundColor: '#17171A',
        color: '#FFFFFF',
      },
      small: {
        backgroundColor: '#F1F3F5',
        color: '#53585F',
        fontSize: '14px',
        padding: '5px 12px',
        margin: '0',
      },
      'small-dark': {
        backgroundColor: '#17171A',
        color: '#FFFFFF',
        fontSize: '14px',
        padding: '5px 12px',
        margin: '0',
        height: '36px',
      },
      'small-active': {
        backgroundColor: '#72BF9C',
        color: '#FFFFFF',
        fontSize: '14px',
        padding: '5px 12px',
        margin: '0',
      },
      white: {
        backgroundColor: '#FFFFFF',
        color: '#17171A',
        border: '1px solid #DFDFDF',
      },
    },
  },
  '&:hover': {
    opacity: 0.9,
  },
});

type Props = {
  text?: string;
  type: 'default' | 'active' | 'dark' | 'small' | 'small-active' | 'white' | 'small-dark';
  className?: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

export default function PrimaryButton({
  text,
  type = 'default',
  className,
  ...props
}: Props) {
  return (
    <Button 
      className={className}
      type={type}
      {...props}
    >
      <span>
        {text}
      </span>
    </Button>
  );
}
