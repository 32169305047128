import React, { useEffect, useState } from 'react';
import styled from '../../utils/styled';

import TitleNav from '../../components/TitleNav';
import Button from '../../components/Button';

import CheckIcon from '../../assets/icons/check-circle.png';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Container = styled('div', {
  width: '100%',
  position: 'relative',
});

const Content = styled('div', {
  height: '100dvh',
  padding: '0 20px',
  paddingBottom: '53px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const CheckImage = styled('img', {
  width: '80px',
  height: '80px',
});

const Title = styled('h2', {
  color: '#17171A',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '150%',
  letterSpacing: '-0.4px',
  margin: '24px 0 4px 0',
});

const Description = styled('p', {
  color: '#5F5F60',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-0.14px',
  margin: 0,
});

const Notice = styled('div', {
  width: '100%',
  borderRadius: '6px',
  backgroundColor: '#F6F6F6',
  border: 'none',
  padding: '12px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '24px 0 40px 0',
});

const NoticeText = styled('p', {
  color: '#17171A',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '-0.14px',
  textAlign: 'center',
  margin: 0,
});

export default function Complete() {
  const [searchParams] = useSearchParams();
  const [contact, setContact] = useState<string | null>(null);
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/', { replace: true });
  };
  
  useEffect(() => {
    const queryContact = searchParams.get('contact');
    if (queryContact) {
      setContact(queryContact);
    }
  }, []);

  return (
    <Container>
      <TitleNav text="내 이력서 관리" />
      <Content>
        <CheckImage src={CheckIcon} />
        <Title>이력서 등록 완료!</Title>
        <Description>이력서가 정상적으로 등록되었어요.</Description>
        <Notice>
          <NoticeText>기업에서 회원님을 찾을 때,<br />{contact} 번호로 채용 제안을 드릴게요.</NoticeText>
        </Notice>
        <Button className='w-full' text='홈으로 가기' type='white' onClick={goToHome} />
      </Content>
    </Container>
  );
}